<template>
  <component
      :is="field.component || 'PlainInput'"
      v-model="inputVal"
      :options="field.options"
      :placeholder="field.label"
      :format="field.format"
      :class="getClass(field)"
      :field="field.name"
      @blur="handleBlur(field)"
      @input="handleInput($event, field)"
      :disabled="!!disabled"
      :querySearch="querySearch"
      :isWWC="isWWC"
      :type="type"
      v-if="field.displayed === undefined || field.displayed() === true"
      :titleHtmlify="titleHtmlify"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import SelectSP from '@/components/form/fields/SelectSP.vue';
import RadioSP from '@/components/form/fields/RadioSP.vue';
import CheckboxSP from '@/components/form/fields/CheckboxSP.vue';
import PlainInput from '@/components/form/fields/PlainInput.vue';
import DateInput from '@/components/form/fields/DateInput.vue';
import validate from '@/utils/validators';
import TypedSelect from '@/components/form/fields/TypedSelect.vue';
import GroupedSelect from '@/components/form/fields/GroupedSelect.vue';

export default {
  name: 'SuperInput',
  components: {
    SelectSP,
    RadioSP,
    PlainInput,
    DateInput,
    TypedSelect,
    GroupedSelect,
    CheckboxSP,
  },
  props: {
    field: Object,
    value: null,
    disabled: Boolean,
    errors: Array,
    querySearch: Function,
    isWWC: Boolean,
    type: String,
    titleHtmlify: Boolean,
  },
  computed: {
    ...mapGetters('views', ['bus']),
  },
  watch: {
    bus() {
      this.$emit('update:errors', validate(this.field.validators, this.inputVal));
    },
  },
  methods: {
    validate,
    getClass(field) {
      return field.errors.length === 0 ? null : 'invalid';
    },
    handleBlur() {
      this.$emit('blur'); // must emit first then validate
      this.$emit('update:errors', validate(this.field.validators, this.inputVal));
    },
    handleInput() {
      this.$emit('input', this.inputVal); // must emit first then validate
      this.$emit('damn', this.inputVal);
      this.$emit('update:errors', validate(this.field.validators, this.inputVal));
    },
  },
  data() {
    return {
      inputVal: this.value,
    };
  },
};
</script>

<style lang="scss" scoped>

 .el-date-editor {
    width: 100% !important;

    ::v-deep .el-input__inner {
      text-align: right;
    }
  }

  .el-icon-date {
    &::before {
      content: "Birthday" !important;
      font-family: $fontRegular;
    }
  }


  // new animated for selects
  // ::v-deep .el-select {
  //   + label {
  //     position: absolute;
  //     top: 2px;
  //     left: 0;
  //     font-size: 0.875rem;
  //     color: #909090;
  //     font-weight: 300;
  //     transform: translate3d(0, 0, 0);
  //     transition: all 0.2s ease-in-out;
  //   }
  // }

  .el-select {
    width: 100%;
  }

  ::v-deep input.el-input__inner {
    padding-top: 15px;
    padding-left: 0;
  }

  // animations
  // ::v-deep input:focus + label, .has-text + label {
  //   font-weight: 700;
  //   font-size: 12px;
  //   transform: translate3d(0, -10px, 0);
  // }

  // ::v-deep input:focus + label {
  //   color: $secondary;
  // }

  ::v-deep .el-icon-date {
    &::before {
      content: "Birthday" !important;
      font-family: $fontRegular;
    }
  }
</style>
