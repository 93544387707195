<template>
  <div class="form-control">
    <input
      :id="`${type}_${field}`"
      :name="field"
      :type="type"
      :required="!!required"
      :disabled="!!disabled"
      :autocomplete="autocomplete"
      v-model="inputVal"
      class="full-width button-transparent "
      @blur="$emit('blur')"
      :class="inputVal ? 'has-text' : null"
    />
    <label :for="field">
      <span v-if="titleHtmlify" v-html="urlify(placeholder)"></span>
      <span v-else class="no-block">{{ placeholder }}</span>
    </label>
  </div>
</template>

<script>
import { urlify } from "@/utils/misc";

export default {
  name: "PlainInput",
  props: {
    value: [String, Number],
    field: String,
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text" // possible: [number, text, textarea]
    },
    autocomplete: {
      type: String,
      default: "off"
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    titleHtmlify: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      urlify,
      inputVal: this.value
    };
  },
  watch: {
    inputVal(val) {
      this.$emit("input", this.type === "number" ? +val : val);
    }
  }
};
</script>

<style lang="scss" scoped>
.form-control {
  border: none;
  position: relative;
  padding: 0;

  input {
    padding: 25px 12px 6px 0 !important;
    height: 45px !important;
    font-size: 0.85rem;
    color: $secondary;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    box-shadow: none !important;
    border-bottom: solid 2px lightgrey;
    transition: border-color 0.5s;

    &:disabled {
      background-color: #f5f7fa;
    }
  }

  label {
    position: absolute;
    bottom: 5px;
    left: 0;
    color: #909090;
    font-weight: 300;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease-in-out;
  }
}

input:focus + label,
.has-text + label {
  font-weight: 700;
  font-size: 12px;
  transform: translate3d(0, -1.1rem, 0);
}

input:focus + label {
  color: $secondary;
}
</style>
